import React from 'react';
import styled from 'styled-components';
import {color, fontSize, fontFamily, space, query, fontWeight} from '@theme/fn';
import {Container, LayoutProps} from './Layout';
import {SimpleComponent} from '@util/types';
import {font} from '@design/templateFns';

const ErrorContainer = styled(Container)`
  background: ${color('cream')};
  text-align: left;
`;

export const Header = styled.h1`
  ${font('headline-xl')};
  margin-top: 95px;
  margin-bottom: 22px;

  @media screen and (${query.atLeast('tablet')}) {
    ${font('headline-xxl')};
    line-height: 125%;
    margin-top: 25px;
  }
`;

export const Text = styled.p`
  ${font('body-lg-regular')};
  margin: ${space('md')} 0 ${space('lg')};
  max-width: 681px;
  font-size: 18px;
  @media screen and (${query.atLeast('tablet')}) {
    margin: ${space('md')} 0 ${space('x10')};
    font-size: 22px;
  }
`;

export const ButtonWrapperForSpacing = styled.div`
  margin-bottom: ${space('x12')};
`;
export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    margin-bottom: ${space('md')};
  }

  @media screen and (${query.atLeast('tablet')}) {
    flex-direction: row;

    > div {
      margin-right: ${space('md')};
      margin-bottom: 0;
    }
  }
`;

const ErrorLayout: SimpleComponent<LayoutProps> = (props) => {
  return <ErrorContainer {...props} />;
};
export default ErrorLayout;
